export const mbaFinance = {
    description: "L’Executive MBA est un diplôme professionnel délivré par le Centre d’Études en Diplomatie et Management – SONNI d’une durée de 10 mois en formation à distance sur la plateforme Zoom. La formation est dispensée deux fois par semaine, le plus souvent les week-ends, et basée sur le développement des compétences et capacités en gestion, avec une spécialité en Management des Risques Financiers et Stratégies d’Investissement. Les modules et un projet de soutenance permettront aux participants de développer les compétences visées avec l’accompagnement du corps pédagogique. Les intervenants sont des experts internationaux, principalement d’Afrique et d’Europe, avec une expérience enrichissante dans la formation des cadres supérieurs. La durée globale de la formation est de 8 mois de cours, suivis de 2 mois pour la préparation d’un mémoire d’application.",
    objectifs: [
    "Analyser et anticiper les risques financiers dans divers environnements économiques.",
    "Développer des stratégies d’investissement adaptées aux dynamiques du marché.",
    "Gérer des portefeuilles financiers et optimiser le rendement tout en minimisant les risques.",
    "Maîtriser les outils financiers avancés pour la gestion des risques et l’investissement."
    ],
    style_pedagogique: "Notre Executive MBA est basé sur l’interaction entre l’expert formateur et les auditeurs. Chaque enseignement théorique est suivi d’un cas pratique. L’évaluation s’effectue sous forme d’un exposé de présentation.",
    cibles: [
    "Dirigeants d’entreprise",
    "Cadres supérieurs",
    "Managers et futurs managers"
    ],
    atouts: [
    "Conférence internationale sur la finance internationale",
    "Séminaires sur la gestion des risques des entreprises à Dakar",
    "Édition de votre mémoire en livre aux éditions SONNI",
    "Découvertes sur Gorée",
    "graduation Cérémonie à Dakar, suivie de conférences, séminaires et visites touristiques",
    ],
    infos_supplementaires: [
        "Programme 100% en ligne",
        "Durée de la formation 8 Mois",

],
    cout: "2000 Euros avec possibilité de paiement en plusieurs tranches"
}

export const relaInter = {
    description: "L’Executive MBA est un diplôme professionnel délivré par le Centre d’Études en Diplomatie et Management – SONNI et d’une durée de 10 mois en formation à distance sur la plateforme Zoom. La formation est dispensée deux fois par semaine, le plus souvent les week-ends, et basée sur le développement des compétences et capacités en gestion, avec une spécialité en Diplomatie, Relations Internationales et Communication d’Influence. Les modules et un projet de soutenance permettront aux participants de développer les compétences visées avec l’accompagnement du corps pédagogique. Les intervenants sont des experts internationaux, principalement d’Afrique et d’Europe, avec une expérience enrichissante dans la formation des cadres supérieurs. La durée globale de la formation est de 8 mois de cours, suivis de 2 mois pour la préparation d’un mémoire d’application.",
        objectifs: [
        "Mener des négociations internationales complexes et faire preuve de diplomatie interculturelle.",
        "Analyser les dynamiques géopolitiques et économiques globales.",
        "Développer des stratégies de communication et d’influence pour le secteur privé et public.",
        "Gérer des projets internationaux et des crises diplomatiques."
    ],
        style_pedagogique: "Notre Executive MBA est basé sur l’interaction entre l’expert formateur et les auditeurs. Chaque enseignement théorique est suivi d’un cas pratique. L’évaluation s’effectue sous forme d’un exposé de présentation.",
        cibles: [
        "Professionnels en milieu diplomatique, international et politique.",
        "Dirigeants, consultants, et managers souhaitant maîtriser les enjeux internationaux et la communication d’influence.",
        "Diplomates ou fonctionnaires internationaux.",
        "Consultants en relations internationales.",
        "Experts en communication et enjeux internationaux.",
        "Officiers supérieurs des Armées.",
        "Étudiants et jeunes diplômés souhaitant une spécialisation de haut niveau en relations internationales et diplomatie."
    ],
        atouts: [
        "Conférence internationale sur la finance internationale.",
        "Séminaires sur la gestion des risques des entreprises à Dakar.",
        "Édition de votre mémoire en livre aux éditions SONNI.",
        "Découvertes sur Gorée",
        "graduation Cérémonie à Dakar, suivie de conférences, séminaires et visites touristiques",
    ],
    infos_supplementaires: [
        "Programme 100% en ligne",
        "Durée de la formation 8 Mois",

    ],

    "contact": {
        "email": "contact@sonni-group.com",
            "telephone": "+221 77 216 27 85",
            "site_web": "https://www.sonni-group.com"
    },
    cout: "2000 Euros avec possibilité de paiement en plusieurs tranches"
}

